export class Settings {
    public constructor(settings: any) {
        Object.assign(this, settings);
    }
}
export interface Settings {
    base: string;
    url(url: string): string;
    name: string;
    author: string;
    program: string;
    description: string;
    minDate: string;
    mainMenu: Array<{ icon: [string, string]; title: string; to: string; exact: boolean }>;
    errorPages: {
        default: { icon: [string, string]; title: string; message: string; statusCode: number };
        [code: string]: { icon: [string, string]; title: string; message: string; statusCode: number };
    };
    card: string;
    headerLogo: string;
    footerLogo: string;
    footerDrugLogo: string;
    footerPharmaLogo: string;
    cardTypeQuestionId: string;
    cardTypeAnswerId: string;
    programId: string;
    surveyId: string;
    isFederalQuestionId: string;
    isFederalAnswerId: string;
    isFederalAnswerYesId: string;
    patientDiagnosisQuestionId: string;
    patientDiagnosisC4A0AnswerId: string;
    patientDiagnosisC4A1AnswerId: string;
    patientDiagnosisC4A10AnswerId: string;
    patientDiagnosisC4A11AnswerId: string;
    patientDiagnosisC4A12AnswerId: string;
    patientDiagnosisC4A2AnswerId: string;
    patientDiagnosisC4A20AnswerId: string;
    patientDiagnosisC4A21AnswerId: string;
    patientDiagnosisC4A22AnswerId: string;
    patientDiagnosisC4A3AnswerId: string;
    patientDiagnosisC4A30AnswerId: string;
    patientDiagnosisC4A31AnswerId: string;
    patientDiagnosisC4A4AnswerId: string;
    patientDiagnosisC4A6AnswerId: string;
    patientDiagnosisC4A60AnswerId: string;
    patientDiagnosisC4A61AnswerId: string;
    patientDiagnosisC4A62AnswerId: string;
    patientDiagnosisC4A7AnswerId: string;
    patientDiagnosisC4A70AnswerId: string;
    patientDiagnosisC4A71AnswerId: string;
    patientDiagnosisC4A72AnswerId: string;
    patientDiagnosisC4A5AnswerId: string;
    patientDiagnosisC4A51AnswerId: string;
    patientDiagnosisC4A52AnswerId: string;
    patientDiagnosisC4A59AnswerId: string;
    patientDiagnosisC4A8AnswerId: string;
    patientDiagnosisC7B1AnswerId: string;
    patientDiagnosisC4A9AnswerId: string;
    patientDiagnosisZ85821AnswerId: string;
    patientDiagnosisC4AAnswerId: string;
    patientDiagnosisOtherAnswerId: string;
    doesAgreeMarketingQuestionId: string;
    doesAgreeMarketingAnswerId: string;
    triangleUp: string;
    triangleDown: string;
    lockEnabled: string;
    lockPassword: string;
}
