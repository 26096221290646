














import 'vue-wait';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Resolve } from 'vue-di';
import LayoutModule from '~/store/layout';

@Component({})
export default class MagentaText extends Vue {
    @Resolve
    public layout!: LayoutModule;

    @Prop()
    public innerHTML!: string;

    @Prop()
    public isSvg!: boolean;

    public get text() {
        return this.layout.isMagenta ? `<<${this.innerHTML}>>` : this.innerHTML;
    }
}
