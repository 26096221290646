import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a2716214 = () => interopDefault(import('..\\pages\\error.vue' /* webpackChunkName: "pages_error" */))
const _7b29bcc8 = () => interopDefault(import('..\\pages\\reimbursement\\index.vue' /* webpackChunkName: "pages_reimbursement_index" */))
const _5e5ff512 = () => interopDefault(import('..\\pages\\rejection.vue' /* webpackChunkName: "pages_rejection" */))
const _cff9e774 = () => interopDefault(import('..\\pages\\unlock.vue' /* webpackChunkName: "pages_unlock" */))
const _5e62dc15 = () => interopDefault(import('..\\pages\\enroll\\eligibility.vue' /* webpackChunkName: "pages_enroll_eligibility" */))
const _3553ed41 = () => interopDefault(import('..\\pages\\enroll\\insurance-information.vue' /* webpackChunkName: "pages_enroll_insurance-information" */))
const _7b165128 = () => interopDefault(import('..\\pages\\enroll\\patient-information.vue' /* webpackChunkName: "pages_enroll_patient-information" */))
const _23a6002c = () => interopDefault(import('..\\pages\\enroll\\prescriber-information.vue' /* webpackChunkName: "pages_enroll_prescriber-information" */))
const _6700458b = () => interopDefault(import('..\\pages\\enroll\\success.vue' /* webpackChunkName: "pages_enroll_success" */))
const _113ef517 = () => interopDefault(import('..\\pages\\errors\\400.vue' /* webpackChunkName: "pages_errors_400" */))
const _114d0c98 = () => interopDefault(import('..\\pages\\errors\\401.vue' /* webpackChunkName: "pages_errors_401" */))
const _11693b9a = () => interopDefault(import('..\\pages\\errors\\403.vue' /* webpackChunkName: "pages_errors_403" */))
const _1177531b = () => interopDefault(import('..\\pages\\errors\\404.vue' /* webpackChunkName: "pages_errors_404" */))
const _46253058 = () => interopDefault(import('..\\pages\\errors\\500.vue' /* webpackChunkName: "pages_errors_500" */))
const _6b0130c0 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('#{BasePath}#/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/error",
    component: _a2716214,
    name: "error"
  }, {
    path: "/reimbursement",
    component: _7b29bcc8,
    name: "reimbursement"
  }, {
    path: "/rejection",
    component: _5e5ff512,
    name: "rejection"
  }, {
    path: "/unlock",
    component: _cff9e774,
    name: "unlock"
  }, {
    path: "/enroll/eligibility",
    component: _5e62dc15,
    name: "enroll-eligibility"
  }, {
    path: "/enroll/insurance-information",
    component: _3553ed41,
    name: "enroll-insurance-information"
  }, {
    path: "/enroll/patient-information",
    component: _7b165128,
    name: "enroll-patient-information"
  }, {
    path: "/enroll/prescriber-information",
    component: _23a6002c,
    name: "enroll-prescriber-information"
  }, {
    path: "/enroll/success",
    component: _6700458b,
    name: "enroll-success"
  }, {
    path: "/errors/400",
    component: _113ef517,
    name: "errors-400"
  }, {
    path: "/errors/401",
    component: _114d0c98,
    name: "errors-401"
  }, {
    path: "/errors/403",
    component: _11693b9a,
    name: "errors-403"
  }, {
    path: "/errors/404",
    component: _1177531b,
    name: "errors-404"
  }, {
    path: "/errors/500",
    component: _46253058,
    name: "errors-500"
  }, {
    path: "/",
    component: _6b0130c0,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
