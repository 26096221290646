























































































































































































































import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component<Isi>({})
export default class Isi extends Vue {}
