






























































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import TermsAndConditions from '~/components/TermsAndConditions.vue';
import Isi from '~/components/Isi.vue';

@Component<Footer>({
    components: {
        TermsAndConditions,
        Isi,
    }
})
export default class Footer extends Vue {
    // [x: string]: any;
    @Prop()
    public brkPoints!: object;
    @Prop({ default: false })
    public isFixed!: boolean;

    public dialog = false;

    // mounted(){
    //     this.terms = document.querySelector('#patient-terms-and-conditions')
    // }

    scrollToTerms() {
        const element = document.querySelector("#patient-terms-and-conditions")
        if (element) {
            element.scrollIntoView({
                block: "nearest",
                inline: "center",
                behavior: "smooth",
            });
            return;
        }
    }

}
