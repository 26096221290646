













import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import MagentaText from '~/components/MagentaText.vue';

@Component<TermsAndConditions>({
    components: {
        MagentaText,
    }
})
export default class TermsAndConditions extends Vue {}
