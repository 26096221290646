import { Mutation } from 'vuex-module-decorators';
import { InjectModule, InjectVuexModule } from 'vue-di/vuex';
import { SurveySessionValueModel } from '@trialcard/apigateway.models';

export interface IEligibilityData {
    eligibilityFederal?: string;
    diagnosis?: string;
    doesAgreeMarketing?: string;

}
@InjectModule({ stateFactory: true }, module)
export default class EligibilityModule extends InjectVuexModule {

    public eligibilityData: SurveySessionValueModel[] = [];

    @Mutation
    public async updateEligibilityData(newEligibilityData: typeof EligibilityModule.prototype.eligibilityData) {
        this.eligibilityData = newEligibilityData;
    }

}
